<template>
  <center class="pt-1">
    <b-img
      v-if="type === 2"
      :src="`${assetsImg + '/images/icons/paymenttype-manual.ico'}`"
      style="left: 0; top: 50%; transform: translate(0, -50%)"
      title="Manual"
    />
    <b-img
      v-else-if="type === 43"
      :src="`${assetsImg + '/images/icons/zero_payment.ico'}`"
      style="
        left: 0;
        top: 50%;
        transform: translate(0, -50%);
        width: 20px;
        text: black;
      "
      :style="[isDarkSkin ? '' : 'filter: invert(100%);']"
      title="Zero Payment"
    />
    <b-img
      v-else-if="[12, 15, 21].includes(type)"
      :src="`${assetsIm + '/images/icons/paymenttype-others.ico'}`"
      style="left: 0; top: 50%; transform: translate(0, -50%)"
      title="Others"
    />
    <b-img
      v-else-if="isAuto"
      :src="`${
        isAutoDecline
          ? assetsImg + '/images/icons/paymenttype-auto-no.ico'
          : assetsImg + '/images/icons/paymenttype-auto.ico'
      }`"
      style="left: 0; top: 50%; transform: translate(0, -50%)"
      title="Automatic"
    />
  </center>
</template>
<script>
export default {
  props: {
    type: {
      type: Number,
    },
    status: {
      type: String,
      default: "Decline",
    },
  },
  data() {
    return {
      assetsImg: process.env.VUE_APP_BASE_URL_ASSETS,
    };
  },
  computed: {
    isAuto() {
      return this.type === 1;
    },
    isAutoDecline() {
      return this.type === 1 && this.status == "Declined";
    },
  },
};
</script>