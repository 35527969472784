<template>
  <div>
    <h2>{{ title }}</h2>
    <FilterSlot
      :filter="filters"
      :filter-principal="filterPrincipal"
      :total-rows="totalRows"
      :paginate="pagination"
      :start-page="startPage"
      :to-page="toPage"
      @reload="$refs.debtorClients.refresh()"
    >
      <template #table>
        <b-table
          ref="debtorClients"
          no-provider-filtering
          :items="provider"
          :fields="fields"
          table-class="text-nowrap"
          slot="table"
          responsive="sm"
          show-empty
          :busy.sync="isBusy"
          :current-page.sync="pagination.currentPage"
          :per-page.sync="pagination.perPage"
        >
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-1" />
              <strong>Loading ...</strong>
            </div>
          </template>
          <template #cell(client)="{ value, item }">
            <b-col>
              <b-row>
                <router-link
                  :to="{
                    name: 'credit-experts-dashboard',
                    params: { idClient: item.client_account_id },
                  }"
                  target="_blank"
                >
                  {{ value }}
                </router-link>
              </b-row>
              <b-row>
                {{ item.mobile }}
              </b-row>
              <b-row>
                {{ item.account }}
              </b-row>
            </b-col>
          </template>
          <template #cell(type_transaction)="{ item }">
            <PaymentIcon
              :type="item.type_transaction_id"
              :status="item.status_transaction"
            />
          </template>
          <template #cell(settlement_date)="{ item, value }">
            <b-col>
              <b-row class="d-flex justify-content-center">
                <span class="text-center font-weight-bolder">
                  $ {{ item.amount }}
                </span>
              </b-row>
              <b-row class="d-flex justify-content-center">
                <span>
                  {{ value | myGlobalDay }}
                </span>
              </b-row>
            </b-col>
          </template>
          <template #cell(client_result_tracking)="{ item }">
            <center class="m-1">
              <FeatherIcon
                icon="ListIcon"
                class="text-primary cursor-pointer"
                size="20"
                @click="openResultTracking(item.transaction_id)"
              />
            </center>
          </template>
          <template #cell(client_result)="{ item }">
            <ResultSelect
              :client_account_id="item.client_account_id"
              :status="item.client_result_status"
              :transaction_id="item.transaction_id"
              :result_id="item.client_result_id"
              :results="results"
              @refreshTable="refreshDetailTable"
            />
          </template>
          <template #cell(client_label_status)="{ value }">
            <center class="pt-1">
              <b-badge
                v-if="value == 1"
                variant="light-primary"
                style="font-size: 14px"
              >
                Pending
              </b-badge>
              <b-badge
                v-else-if="value == 2"
                variant="light-success"
                style="font-size: 14px"
              >
                Approve
              </b-badge>
              <b-badge
                v-else-if="value == 3"
                variant="light-danger"
                style="font-size: 14px"
              >
                Decline
              </b-badge>
            </center>
          </template>
          <template #cell(client_label)="{ item }">
            <ClientLabelSelect
              :status="item.client_label_status"
              :label_id="item.client_label_id"
              :transaction_id="item.transaction_id"
              :client_account_id="item.client_account_id"
              :other_label="item.other"
              :labels="labels"
              @refreshTable="refreshDetailTable()"
            />
          </template>
          <template #cell(client_label_tracking)="{ item }">
            <center class="m-1">
              <FeatherIcon
                icon="ListIcon"
                class="text-primary cursor-pointer"
                size="20"
                @click="openLabelTracking(item.client_account_id)"
              />
            </center>
          </template>
        </b-table>
      </template>
    </FilterSlot>

    <ModalResultTracking
      v-if="modalResultActive"
      :transactionId="transaction_id"
      @close="closeResultModal"
    />
    <ModalLabelTracking
      v-if="modalLabelActive"
      :accountId="accountId"
      @close="closeLabelModal"
    />
  </div>
</template>
<script>
// Import Components
import FilterSlot from "@/views/crm/views/sales-made/components/slots/FilterSlot.vue";
import ResultSelect from "./ResultSelect.vue";
import ClientLabelSelect from "./ClientLabelSelect.vue";
import ModalResultTracking from "./ModalResultTracking.vue";
import ModalLabelTracking from "./ModalLabelTracking.vue";
import PaymentIcon from "./PaymentIcon.vue";
// Import Vuex
import { mapGetters } from "vuex";
// Import fields table
import { fieldsDetailTable } from "../data/fileds.data";
// Import filters
import { filterPrincipal, filters } from "../data/filters.data";
import DebtorClientsService from "@/views/commons/components/reports/tabs/debtor-clients/service/debtor-clients.service.js";

export default {
  components: {
    FilterSlot,
    ResultSelect,
    ClientLabelSelect,
    ModalResultTracking,
    ModalLabelTracking,
    PaymentIcon,
  },
  mounted() {
    this.fields = fieldsDetailTable;
    this.filterPrincipal = filterPrincipal;
    this.filters = filters;
    this.getResults();
    this.getLabels();
  },
  props: {
    option: {
      type: Number,
      default: 4,
    },
    month: Number | String,
    year: Number | String,
  },
  data() {
    return {
      isBusy: false,
      fields: [],
      filterPrincipal: {},
      filters: [],
      resultData: [],
      modalResultActive: false,
      modalLabelActive: false,
      transaction_id: "",
      other: null,
      pagination: {
        currentPage: 1,
        perPage: 10,
      },
      totalRows: 0,
      startPage: 1,
      toPage: 1,
      items: [],
      results: [],
      labels: [],
      accountId: "",
    };
  },
  methods: {
    async getResults() {
      const response = await DebtorClientsService.getResultsClient();
      this.results = response;
    },
    async getLabels() {
      const response = await DebtorClientsService.getLabelsClient();
      this.labels = response;
    },
    async provider(ctx) {
      // this.isBusy = true;
      const params = {
        option: this.option,
        per_page: ctx.perPage,
        current_page: ctx.currentPage,
        user_id: this.userId,
        search: this.filterPrincipal.model,
        result_id: this.filters[0].model,
        label_id: this.filters[1].model,
        month: this.month,
        year: this.year,
      };

      const { from, to, total, data } =
        await DebtorClientsService.getDebtorsDetail(params);
      this.totalRows = total;
      this.startPage = from;
      this.toPage = to;
      // this.items = data;
      // this.isBusy = false;
      return data || [];
    },
    async openResultTracking(transactionId) {
      this.transaction_id = transactionId;

      this.modalResultActive = true;
    },
    async openLabelTracking(accountId) {
      this.accountId = accountId;
      this.other = other;
      this.modalLabelActive = true;
    },
    closeResultModal() {
      this.modalResultActive = false;
    },
    closeLabelModal() {
      this.modalLabelActive = false;
    },
    refreshDetailTable() {
      this.pagination.currentPage = 1;
      this.$refs.debtorClients.refresh();
    },
  },
  computed: {
    ...mapGetters("auth", ["currentUser"]),
    userId() {
      return this.currentUser.user_id;
    },
    title() {
      let title = "Debtors Detail";
      switch (this.option) {
        case 1:
          title = "One month";
          break;
        case 2:
          title = "Two months";
          break;
        case 3:
          title = "Three months";
          break;
        case 4:
          title = "Automatic payments declined";
          break;
        case 5:
          title = "Manual payments";
          break;
        case 6:
          title = "Zero payments";
          break;
      }
      return title;
    },
  },
};
</script>