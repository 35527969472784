<template>
  <b-container class="w-100">
    <b-overlay
      :show="isBusy"
      rounded="sm"
      opacity="0.5"
      :variant="isDarkSkin ? 'dark' : 'light'"
    >
      <b-card-group deck>
        <b-card
          @click="changeCurrentOption(4)"
          class="clickable"
          :border-variant="option !== 4 ? 'success' : 'info'"
          :header-bg-variant="option !== 4 ? 'success' : 'info'"
          footer-class="p-1 d-flex justify-content-center text-primary font-weight-bolder cursor-pointer"
          header="Auto payments declined"
          header-class="p-1 justify-content-center text-white"
          header-tag="header"
          no-body
        >
          <b-card-text>
            <center class="my-1">
              <span
                style="font-size: 20px"
                class="font-weight-bolder text-primary"
              >
                {{ items.auto_payments_declined }}
              </span>
            </center>
          </b-card-text>
        </b-card>
        <b-card
          @click="changeCurrentOption(5)"
          class="clickable"
          :border-variant="option !== 5 ? 'success' : 'info'"
          footer-class="p-1 d-flex justify-content-center text-primary font-weight-bolder cursor-pointer"
          header="Manual payments"
          header-class="p-1 justify-content-center text-white"
          :header-bg-variant="option !== 5 ? 'success' : 'info'"
          header-tag="header"
          no-body
        >
          <b-card-text>
            <center class="my-1">
              <span
                class="font-weight-bolder text-primary"
                style="font-size: 20px"
              >
                {{ items.manual_payments }}
              </span>
            </center>
          </b-card-text>
        </b-card>
        <b-card
          class="clickable"
          @click="changeCurrentOption(6)"
          :border-variant="option !== 6 ? 'success' : 'info'"
          footer-class="p-1 d-flex justify-content-center text-primary font-weight-bolder cursor-pointer"
          header="Zero payment"
          header-class="p-1 justify-content-center text-white"
          :header-bg-variant="option !== 6 ? 'success' : 'info'"
          header-tag="header"
          no-body
        >
          <b-card-text>
            <center class="my-1">
              <span
                class="font-weight-bolder text-primary"
                style="font-size: 20px"
              >
                {{ items.zero_payments }}
              </span>
            </center>
          </b-card-text>
        </b-card>
        <b-card
          class="clickable"
          @click="changeCurrentOption(1)"
          :border-variant="option !== 1 ? 'danger' : 'info'"
          footer-class="p-1 d-flex justify-content-center text-primary font-weight-bolder cursor-pointer"
          header="One month"
          header-class="p-1 justify-content-center text-white"
          :header-bg-variant="option !== 1 ? 'danger' : 'info'"
          header-tag="header"
          no-body
        >
          <b-card-text>
            <center class="my-1">
              <span
                class="font-weight-bolder text-primary"
                style="font-size: 20px"
              >
                {{ items.one_month }}
              </span>
            </center>
          </b-card-text>
        </b-card>
        <b-card
          class="clickable"
          @click="changeCurrentOption(2)"
          :border-variant="option !== 2 ? 'danger' : 'info'"
          footer-class="p-1 d-flex justify-content-center text-primary font-weight-bolder cursor-pointer"
          header="Two months"
          header-class="p-1 justify-content-center text-white"
          :header-bg-variant="option !== 2 ? 'danger' : 'info'"
          header-tag="header"
          no-body
        >
          <b-card-text>
            <center class="my-1">
              <span
                class="font-weight-bolder text-primary"
                style="font-size: 20px"
              >
                {{ items.two_months }}
              </span>
            </center>
          </b-card-text>
        </b-card>
        <b-card
          class="clickable"
          @click="changeCurrentOption(3)"
          :border-variant="option !== 3 ? 'danger' : 'info'"
          header="Three months"
          header-class="p-1 justify-content-center text-white"
          :header-bg-variant="option !== 3 ? 'danger' : 'info'"
          header-tag="header"
          no-body
        >
          <b-card-text>
            <center class="my-1">
              <span
                class="font-weight-bolder text-primary"
                style="font-size: 20px"
              >
                {{ items.three_months }}
              </span>
            </center>
          </b-card-text>
        </b-card>
      </b-card-group>
    </b-overlay>
  </b-container>
</template>
<script>
import { mapGetters } from "vuex";
import DebtorClientsService from "@/views/commons/components/reports/tabs/debtor-clients/service/debtor-clients.service.js";
export default {
  props: {
    month: Number | String,
    year: Number | String,
  },
  data() {
    return {
      isBusy: false,
      items: {
        one_month: "-",
        two_months: "-",
        three_months: "-",
        auto_payments_declined: "-",
        manual_payments: "-",
        zero_payments: "-",
      },
      option: 4,
    };
  },
  async mounted() {
    await this.getItems();
  },
  methods: {
    async getItems() {
      this.isBusy = true;
      const params = {
        user_id: this.userId,
        month: this.month,
        year: this.year,
      };
      this.items = (
        await DebtorClientsService.getCounterDebtorClients(params)
      )[0];
      this.isBusy = false;
    },
    async changeCurrentOption(option) {
      this.option = option;
      this.$emit("changeOption", option);
    },
  },
  computed: {
    ...mapGetters("auth", ["currentUser"]),
    userId() {
      return this.currentUser.user_id;
    },
  },
};
</script>