export const fieldsCountersTable = [
  {
    key: "auto_payments_declined",
    label: "Auto payments declined",
    variant: "success",
    value: 0,
    option: 4,
  },
  {
    key: "manual_payments",
    label: "Manual payments",
    variant: "success",
    value: 0,
    option: 5,
  },
  {
    key: "zero_payments",
    label: "Zero payment",
    variant: "success",
    value: 0,
    option: 6,
  },
  {
    key: "one_month",
    label: "One month",
    variant: "danger",
    value: 0,
    option: 1,
  },
  {
    key: "two_months",
    label: "Two months",
    variant: "danger",
    value: 0,
    option: 2,
  },
  {
    key: "three_months",
    label: "Three months",
    variant: "danger",
    value: 0,
    option: 3,
  },
];

export const fieldsDetailTable = [
  {
    key: "client",
    label: "Client",
  },
  // {
  //     key: 'mobile',
  //     label: 'Mobile'
  // },
  {
    key: "type_transaction",
    label: "Type transaction",
  },
  // {
  //     key: 'status_transaction',
  //     label: 'Status transaction'
  // },
  // {
  //     key: 'amount',
  //     label: 'Amount'
  // },
  {
    key: "settlement_date",
    label: "Last payment",
    thClass: "text-center",
  },
  {
    key: "advisor",
    label: "Client advisor",
  },
  {
    key: "transaction_user",
    label: "Transaction user",
  },
  {
    key: "client_result",
    label: "Result",
    tdClass: "px-0",
    thClass: "text-center",
    // thStyle:{width:'70rem'}
  },
  {
    key: "client_result_tracking",
    label: "Result tracking",
    thClass: "text-center",
  },
  {
    key: "client_label",
    label: "Client label",
    thClass: "text-center",
  },
  {
    key: "client_label_status",
    label: "Label status",
    thClass: "text-center",
  },
  {
    key: "client_label_tracking",
    label: "Tracking label",
  },
];
