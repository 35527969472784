<template>
  <b-container fluid>
    <b-row align-v="center" align-h="center" class="my-2">
      <b-col cols="4">
        <b-form-input
          style="font-size: 20px"
          type="month"
          :value="currentDate"
          @input="changeDate"
        />
      </b-col>
    </b-row>
    <b-row align-v="center" align-h="center" class="my-2">
      <b-col cols="12">
        <CounterDebtorClients
          :month="month"
          :year="year"
          @changeOption="changeOption"
          :key="month + year"
        />
      </b-col>
      <b-col cols="2">
        <FeatherIcon
          icon="SquareIcon"
          size="20"
          style="fill: var(--success); margin-top: 1px; margin-right: 4px"
          class="text-success"
        />
        Without debt
        <span class="mx-2" />
        <FeatherIcon
          icon="SquareIcon"
          size="20"
          style="fill: var(--danger); margin-top: 1px; margin-right: 4px"
          class="text-danger"
        />
        Overdue
      </b-col>
    </b-row>
    <hr />
    <b-row>
      <b-col cols="12">
        <DetailDebtorClients
          :month="month"
          :year="year"
          :option="option"
          :key="option"
          ref="detailDebtor"
        />
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
// Import Components
import CounterDebtorClients from "./components/CounterDebtorClients.vue";
import DetailDebtorClients from "./components/DetailDebtorClients.vue";

export default {
  components: {
    CounterDebtorClients,
    DetailDebtorClients,
  },
  data() {
    return {
      month: "",
      year: "",
      option: 4,
    };
  },
  async created() {},
  methods: {
    changeDate(date) {
      this.month = date.split("-")[1];
      this.year = date.split("-")[0];
      this.$refs.detailDebtor.refreshDetailTable();
    },
    changeOption(option) {
      this.option = option;
    },
  },
  computed: {
    currentDate() {
      const date = new Date();
      const day = date.getDate();
      this.month = date.getMonth() + 1;
      this.year = date.getFullYear();
      if (day == 6) {
        date.setMonth(date.getMonth() - 1);
      }
      return date.toISOString().substr(0, 7);
    },
  },
};
</script>