var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h2',[_vm._v(_vm._s(_vm.title))]),_c('FilterSlot',{attrs:{"filter":_vm.filters,"filter-principal":_vm.filterPrincipal,"total-rows":_vm.totalRows,"paginate":_vm.pagination,"start-page":_vm.startPage,"to-page":_vm.toPage},on:{"reload":function($event){return _vm.$refs.debtorClients.refresh()}},scopedSlots:_vm._u([{key:"table",fn:function(){return [_c('b-table',{ref:"debtorClients",attrs:{"slot":"table","no-provider-filtering":"","items":_vm.provider,"fields":_vm.fields,"table-class":"text-nowrap","responsive":"sm","show-empty":"","busy":_vm.isBusy,"current-page":_vm.pagination.currentPage,"per-page":_vm.pagination.perPage},on:{"update:busy":function($event){_vm.isBusy=$event},"update:currentPage":function($event){return _vm.$set(_vm.pagination, "currentPage", $event)},"update:current-page":function($event){return _vm.$set(_vm.pagination, "currentPage", $event)},"update:perPage":function($event){return _vm.$set(_vm.pagination, "perPage", $event)},"update:per-page":function($event){return _vm.$set(_vm.pagination, "perPage", $event)}},slot:"table",scopedSlots:_vm._u([{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-center text-primary my-2"},[_c('b-spinner',{staticClass:"align-middle mr-1"}),_c('strong',[_vm._v("Loading ...")])],1)]},proxy:true},{key:"cell(client)",fn:function(ref){
var value = ref.value;
var item = ref.item;
return [_c('b-col',[_c('b-row',[_c('router-link',{attrs:{"to":{
                  name: 'credit-experts-dashboard',
                  params: { idClient: item.client_account_id },
                },"target":"_blank"}},[_vm._v(" "+_vm._s(value)+" ")])],1),_c('b-row',[_vm._v(" "+_vm._s(item.mobile)+" ")]),_c('b-row',[_vm._v(" "+_vm._s(item.account)+" ")])],1)]}},{key:"cell(type_transaction)",fn:function(ref){
                var item = ref.item;
return [_c('PaymentIcon',{attrs:{"type":item.type_transaction_id,"status":item.status_transaction}})]}},{key:"cell(settlement_date)",fn:function(ref){
                var item = ref.item;
                var value = ref.value;
return [_c('b-col',[_c('b-row',{staticClass:"d-flex justify-content-center"},[_c('span',{staticClass:"text-center font-weight-bolder"},[_vm._v(" $ "+_vm._s(item.amount)+" ")])]),_c('b-row',{staticClass:"d-flex justify-content-center"},[_c('span',[_vm._v(" "+_vm._s(_vm._f("myGlobalDay")(value))+" ")])])],1)]}},{key:"cell(client_result_tracking)",fn:function(ref){
                var item = ref.item;
return [_c('center',{staticClass:"m-1"},[_c('FeatherIcon',{staticClass:"text-primary cursor-pointer",attrs:{"icon":"ListIcon","size":"20"},on:{"click":function($event){return _vm.openResultTracking(item.transaction_id)}}})],1)]}},{key:"cell(client_result)",fn:function(ref){
                var item = ref.item;
return [_c('ResultSelect',{attrs:{"client_account_id":item.client_account_id,"status":item.client_result_status,"transaction_id":item.transaction_id,"result_id":item.client_result_id,"results":_vm.results},on:{"refreshTable":_vm.refreshDetailTable}})]}},{key:"cell(client_label_status)",fn:function(ref){
                var value = ref.value;
return [_c('center',{staticClass:"pt-1"},[(value == 1)?_c('b-badge',{staticStyle:{"font-size":"14px"},attrs:{"variant":"light-primary"}},[_vm._v(" Pending ")]):(value == 2)?_c('b-badge',{staticStyle:{"font-size":"14px"},attrs:{"variant":"light-success"}},[_vm._v(" Approve ")]):(value == 3)?_c('b-badge',{staticStyle:{"font-size":"14px"},attrs:{"variant":"light-danger"}},[_vm._v(" Decline ")]):_vm._e()],1)]}},{key:"cell(client_label)",fn:function(ref){
                var item = ref.item;
return [_c('ClientLabelSelect',{attrs:{"status":item.client_label_status,"label_id":item.client_label_id,"transaction_id":item.transaction_id,"client_account_id":item.client_account_id,"other_label":item.other,"labels":_vm.labels},on:{"refreshTable":function($event){return _vm.refreshDetailTable()}}})]}},{key:"cell(client_label_tracking)",fn:function(ref){
                var item = ref.item;
return [_c('center',{staticClass:"m-1"},[_c('FeatherIcon',{staticClass:"text-primary cursor-pointer",attrs:{"icon":"ListIcon","size":"20"},on:{"click":function($event){return _vm.openLabelTracking(item.client_account_id)}}})],1)]}}])})]},proxy:true}])}),(_vm.modalResultActive)?_c('ModalResultTracking',{attrs:{"transactionId":_vm.transaction_id},on:{"close":_vm.closeResultModal}}):_vm._e(),(_vm.modalLabelActive)?_c('ModalLabelTracking',{attrs:{"accountId":_vm.accountId},on:{"close":_vm.closeLabelModal}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }