export const filterPrincipal = {
  type: "input",
  inputType: "text",
  placeHolder: "Client...",
  model: "",
};

export const filters = [
  {
    type: "select",
    margin: true,
    showLabel: true,
    label: "Result",
    model: null,
    options: [
      { text: "With results", value: 1 },
      { text: "Without results", value: 2 },
      { text: "No update", value: 3 },
    ],
    reduce: "value",
    selectText: "text",
    cols: 12,
    visible: true,
  },
  {
    type: "select",
    margin: true,
    showLabel: true,
    label: "Client label",
    model: null,
    options: [
      { text: "Upset", value: 1 },
      { text: "Does not answer", value: 2 },
      { text: "Disconnected", value: 3 },
      { text: "No money job", value: 4 },
      { text: "Answer but do not pay", value: 5 },
      { text: "Other", value: 6 },
    ],
    reduce: "value",
    selectText: "text",
    cols: 12,
    visible: true,
  },
  {
    type: "select",
    margin: true,
    showLabel: true,
    label: "Advisor",
    model: null,
    options: [],
    reduce: "id",
    selectText: "name",
    cols: 12,
    visible: false,
  },
];
